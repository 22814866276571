import React from "react";
import Main from "../components/main";
import SEO from "../components/section/seo";
import MfaVerifyComponent from "../components/mfa-verify";
import config from "../config";

export default function MfaVerify() {
  return (
    <Main>
      <SEO title="Shalom College - Portal" />
      <MfaVerifyComponent
        authUrl={config.auth} 
        portalUrl={config.portal} 
        apiUrl={config.backend}
      />
    </Main>
  );
}


